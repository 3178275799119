import React from "react";

const experienceContent = [
  {
    year: "Januar 2024 - heute",
    position: "Teamleiter - Integrated Operations",
    compnayName: "Big Dutchman Service GmbH",
    details: `Als Leiter des Integrated Operations Teams bin ich stolz darauf, ein vielseitiges Team zu leiten, das die Anwendungsbereiche Kommunikation, Zusammenarbeit, Sicherheit, IT-Lebenszyklus und Lizenzierung abdeckt. Unser Fokus liegt nicht nur auf dem reibungslosen Betrieb, sondern auch auf der kontinuierlichen Weiterentwicklung der Dienste in diesen Schlüsselbereichen.
    In enger Zusammenarbeit mit dem Core Operations Team verstehen wir die IT-Infrastruktur als Ganzes. Diese koordinierte Herangehensweise ermöglicht es uns, effizient auf Herausforderungen zu reagieren, innovative Lösungen für komplexe Aufgaben zu entwickeln und sicherzustellen, dass unsere gesamte IT-Infrastruktur reibungslos und effizient funktioniert.
    Die Vielfalt in unseren Anwendungsbereichen erfordert eine hohe Flexibilität, Selbstorganisation und die Fähigkeit, sich schnell an sich ändernde Anforderungen anzupassen. Als Teamleiter liegt mein Fokus dabei darauf, individuelle Stärken innerhalb meines Teams zu identifizieren und zu fördern, damit wir uns kontinuierlich verbessern können.
    Gemeinsam streben wir danach, eine dynamische und hochfunktionale Einheit zu bilden, die nicht nur den Status quo aufrechterhält, sondern ihn auch aktiv gestaltet und dabei höchste Standards in unseren Anwendungsbereichen setzt.`,
  },
  {
    year: "Dezember 2020 - Dezember 2023",
    position: "Teamleiter - IT Projects",
    compnayName: "Big Dutchman Service GmbH",
    details: `Konzeption, Organisation und Durchführung von internationalen IT Projekten | Optimierung der Prozesse innerhalb des Teams | Koordination von beteiligten Mitarbeitern und externen Consultants | Technische Dokumentation der durchgeführten Projekte und Übergabe an IT Operations | Führen und Fördern der Mitarbeiter im Team`,
  },
  {
    year: "August 2019 - November 2020",
    position: "IT Projektmanager",
    compnayName: "Big Dutchman Service GmbH",
    details: `Planung, Organisation und Durchführung von internationalen IT Projekten mit dem Schwerpunkt Unified Communications | Koordination von beteiligten Mitarbeitern und externen Dienstleistern | Dokumentation der erarbeiteten Lösungen und Übergabe an den Support | Schulung der Administratoren und Endanwender vor Ort`,
  },
  {
    year: "Juni 2017 - Juli 2019",
    position: "Unified Communications Administrator",
    compnayName: "Big Dutchman Service GmbH",
    details: `Administration einer Cisco CUCM Kommunikationsinfrastruktur | Planung und Durchführung von Rollouts neuer Sites und Cluster | Schulung der Administratoren und Benutzer des Systems vor Ort | Wartung und Instandhaltung des Systems`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <br></br>
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
