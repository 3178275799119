import React from "react";

const educationContent = [
  {
    year: "2014 - 2017",
    degree: "Fachinformatiker für Systemintegration",
    institute: "BBS Haarentor, Oldenburg",
    details: ``,
  },
  {
    year: "2016",
    degree: "Auslandspraktikum Malaysia",
    institute: "BD Agriculture (Malaysia) Sdn Bhd",
    details: `6-wöchiges Auslandspraktikum an unserem asiatischen Hub Standort in Kuala Lumpur, Malaysia im Zuge der Ausbildung zum Fachinformatiker für Systemintegration`,
  },
  {
    year: "2011 - 2014",
    degree: "Allgemeine Hochschulreife (Abitur)",
    institute: "Adolf-Kolping-Schule, Lohne",
    details: `Schwerpunkt: Informationstechnik`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-graduation-cap"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <br></br>
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
