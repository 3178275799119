import React from "react";

const skillsContent = [
  { skillClass: "p100", skillIcon: "fa fa-group", skillName: "Teamleitung" },
  { skillClass: "p100", skillIcon: "fa fa-fast-forward", skillName: "Prozessoptimierung" },
  { skillClass: "p100", skillIcon: "fa fa-calendar", skillName: "Projektmanagement" },
  { skillClass: "p100", skillIcon: "fa fa-map", skillName: "Jira & Confluence" },
  { skillClass: "p100", skillIcon: "fa fa-phone", skillName: "Cloud Collaboration" },
  { skillClass: "p100", skillIcon: "fa fa-road", skillName: "Strukturierte Arbeitsweise" },
  { skillClass: "p100", skillIcon: "fa fa-plane", skillName: "Reisebereitschaft" },
  { skillClass: "p100", skillIcon: "fa fa-hourglass", skillName: "Zeitmanagement" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
          <span><div className="icon"><i class={val.skillIcon}></i></div></span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
