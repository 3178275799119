import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Wohnort</span>26122 Oldenburg
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">E-Mail</span>{" "}
        <a href="mailto:kontakt@noahnowak.de">kontakt@noahnowak.de</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
