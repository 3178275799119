import React from "react";

const Imprint = () => {
  return (
    <section className="main-content">
      <div className="container">
        {/* Impressum Starts */}
        <div className="row">
            <h3>
              Angaben gem&auml;&szlig; &sect; 5 TMG
            </h3>
            <p>Noah Nowak<br />
            Taubenstr. 2<br />
            26122 Oldenburg</p>
            <h3>
              Kontakt
            </h3>
            <p>E-Mail: kontakt@noahnowak.de</p>
        </div>
        {/* Impressum Ends */}
      </div>
    </section>
  );
};

export default Imprint;
